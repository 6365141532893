#root {
    max-width: 900px;
}

@media (max-width: 900px) {
    #root {
        max-width: 800px;
    }
}

@media (max-width: 576px) {
    #root {
        max-width: 100%;
    }
}
