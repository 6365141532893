$green: #88b119;
$dark-green: #72902b !important;
$darker-green: #4f6911 !important;
$gray: #f7f7f7 !important;
$yellow: #ffeb6c;
$dark-yellow: #f7cb39;
$warning: #f7cb39;
$danger: #e1534b;

@font-face {
  font-family: 'Helvetica Neue';
  src: url(../fonts/Helvetica-Neue.ttf) format('truetype');
}

@font-face {
  font-family: 'Chivo';
  src: url(../fonts/Chivo-Regular.ttf) format('truetype');
}
