@import "src/assets/css/variables";

body {
  font-family: Chivo, sans-serif !important;
}

.App {
  .background-green {
    background-color: $green;
  }

  .background-dark-green {
    background-color: $dark-green;
  }

  .text-yellow {
    color: $dark-yellow;
  }

  .text-green {
    color: $green;
  }

  nav {
    img.logo, img.logo2x {
      display: none !important;
    }

    img.logo2x {
      display: none !important;
    }

    @media (max-width: 800px) {
      img.logo {
        display: block !important;
      }
    }

    @media (min-width: 801px) {
      img.logo2x {
        display: block !important;
      }
    }
  }

  .header-1 {
    //max-width: 167px;

    p.text-white {
      text-align: center;
      font: normal normal normal 10px/13px Chivo;
      letter-spacing: 0;
      text-transform: uppercase;
      opacity: 1;
    }

    p.text-yellow {
      text-align: center;
      font: normal normal bold 14px/15px Chivo;
      letter-spacing: 0;
      opacity: 1;
    }

    h1 {
      text-align: center;
      font: normal normal bold 21px/22px Chivo;
      letter-spacing: 0;
      opacity: 1;
    }

    hr {
      border: 3px solid $dark-yellow;
      opacity: 1;
      width: 50%;
    }
  }

  .header-1-image {
    overflow: hidden;

    img {
      //margin-right: -8px !important;
      //margin-left: -18px !important;
      //width: 113% !important;
    }
  }

  .header-2 {
    p {
      text-align: center;
      font: normal normal normal 14px/16px Chivo;
      letter-spacing: 0;
      color: #FFFFFF;
      opacity: 1;
    }

    p.fw-bold {
      text-align: center;
      font: normal normal bold 17px/17px Chivo;
      letter-spacing: 0;
      color: #FFFFFF;
      opacity: 1;
    }

    span {
      font: normal normal bold 22px/13px Chivo;
    }

    span.span-150 {
      font: normal normal bold 19px/13px Chivo;
    }

    span.span-remise, sup.asterisk {
      font: normal normal bold 13px/13px Chivo;
    }
  }

  .title-header-2 {
    font: normal normal bold 20px/20px Chivo;
  }

  .image-header-2 {
    width: 100%;
  }

  div.mentions-legales {
    background-color: $gray;
    text-align: left;
    font: italic normal normal 10px/13px "Chivo";
    letter-spacing: 0;
    color: #686064;
    opacity: 1;
  }

  @media (min-width: 801px) {
    .header-1 {
      p.text-white {
        font: normal normal normal 20px/33px Chivo;
      }

      p.text-yellow {
        font: normal normal bold 30px/27px Chivo;
      }

      h1 {
        font: normal normal 40px/45px Chivo;
      }
    }

    .title-header-2 {
      font: normal normal bold 45px/45px Chivo;
    }

    .image-header-2 {
      width: 60%;
    }

    .header-2 {
      p {
        font: normal normal normal 25px/27px Chivo;
      }

      p.fw-bold {
        font: normal normal bold 40px/40px Chivo;
      }

      span {
        font: inherit;
      }

      span.span-150 {
        font: inherit;
      }

      span.span-remise, sup.asterisk {
        font: normal normal bold 25px/25px Chivo;
      }
    }

    div.mentions-legales {
      font: italic normal normal 13px/15px "Chivo";
    }
  }
}
