@import "src/assets/css/variables";

#FormSended {
  background-color: $gray;
  p.text-gray {
    font: italic normal normal 10px/13px "Chivo";
    letter-spacing: 0;
    color: #686064;
    opacity: 1;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  a.btn-remise {
    font: normal normal bold 15px/15px Chivo;
  }
}
