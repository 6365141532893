@import "src/assets/css/variables";

$etapeArrowWidth: 10px;

.AppForm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));

  .loader__overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(34, 34, 34, 0.5);

    .overlay__inner {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .overlay__content {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .spinner {
      width: 100px;
      height: 100px;
      display: inline-block;
      border-width: 10px;
      border-color: rgba(255, 255, 255, 0.15);
      border-top-color: #fff;
      animation: spin 1s infinite linear;
      border-radius: 100%;
      border-style: solid;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .Form-images {
    text-align: center;
    background-color: $dark-green;

    .dropzone {
      background-color: $gray;
      min-height: 15rem;
      border: 8px dashed $green;
      //border-radius: 14px;
      position: relative;
      //box-shadow: 0 10px 7px $darker-green;
      box-shadow: 0 14px 15px #00000029;
      //background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%2388B119FF' stroke-width='15' stroke-dasharray='1%2c 26' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 14px;

      p {
        text-align: center;
        font: normal normal normal 14px/15px Chivo;
        letter-spacing: 0px;
        color: #686064;
        opacity: 1;
      }

      input {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
      }

      button {
        text-align: center;
        font: normal normal bold 15px/20px Helvetica Neue;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
      }

      img {
        width: 45px;
      }
    }

    img {
      border: 5px solid $green;
      filter: brightness(100%);
      transition: .3s ease;
    }

    .container-image {
      $width-ci: 100%;
      $height-ci: 75%;

      position: relative;

      img {
        width: $width-ci;
        height: $height-ci;
      }

      //@media (max-width: 400px) {
      //  img {
      //    height: 7rem;
      //  }
      //}

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: $width-ci;
        height: $height-ci;
        opacity: 1;
        transition: .3s ease;

        .icon {
          color: white;
          position: absolute;
          bottom: 0;
          right: 0;
          transform: translate(-70%, -20%);
          text-align: center;
          background-color: #808080;
          padding: 0 0.4rem;
          border: none;
          border-radius: 50em;
          margin: 0.2rem;
        }

        .fa:hover {
          color: #eee;
        }
      }

      .container-image:hover img.true-image {
        filter: brightness(45%);
      }
    }

    .conseil-title {
      text-align: center;
      font: normal normal bold 14px/17px Chivo;
      letter-spacing: 0;
      color: $yellow;
      opacity: 1;
    }

    .carousel-item p {
      font-size: 0.8rem;
      text-align: left;
    }

    .carousel-control-prev, .carousel-control-next {
      position: relative;
    }
  }

  .Etape {
    background-color: $dark-yellow;
    color: $darker-green;
    text-align: center;
    font: italic normal bold 13px/13px Chivo;
    letter-spacing: 0;
    opacity: 1;
  }

  .Etape-arrow {
    width: 0;
    height: 0;
    border-left: $etapeArrowWidth solid transparent;
    border-right: $etapeArrowWidth solid transparent;
    position: absolute;
    left: calc(50% - #{$etapeArrowWidth});
    border-top: $etapeArrowWidth solid $dark-yellow;
  }

  .Form-input {
    background-color: $gray;

    input.form-control {
      border: none;
    }

    p.label-required {
      font: normal normal normal 10px/7px Chivo;
      letter-spacing: 0;
      color: #88B119;
      opacity: 1;
    }

    label.form-check-label {
      color: $green;
      text-align: left;
      font: normal normal normal 12px/15px Chivo;
      letter-spacing: 0;
      opacity: 1;
    }

    label.form-label {
      text-align: left;
      font: normal normal normal 15px/25px Chivo;
      letter-spacing: 0;
      color: $green;
      opacity: 1;
      margin-bottom: 0 !important;
    }

    .form-check-input[type="checkbox"] {
      border-radius: 0.5em;
      font-size: xx-large;
    }

    .form-check-input:checked {
      background-color: $green;
      border-color: $green;
    }

    //p.invalid-feedback {
    //  display: none;
    //}
    //
    //input:invalid ~ p.invalid-feedback {
    //  display: block;
    //  color: red;
    //}

    .disclaimer {
      text-align: left;
      font: italic normal normal 10px/13px "Chivo";
      letter-spacing: 0;
      color: #686064;
      opacity: 1;

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    span.required {
      color: $green;
    }

    button.btn-submit {
      text-align: center;
      font: normal normal 17px/21px 'Helvetica Neue';
      letter-spacing: 0;
      color: #FFFFFF;
      text-transform: uppercase;
      opacity: 1;
    }
  }

  @media (min-width: 801px) {
    .Etape {
      font: italic normal bolder 35px/40px Chivo;
    }

    .Etape-arrow {
      border-left: calc(2 * #{$etapeArrowWidth}) solid transparent;
      border-right: calc(2 * #{$etapeArrowWidth}) solid transparent;
      left: calc(50% - 2 * #{$etapeArrowWidth});
      border-top: calc(2 * #{$etapeArrowWidth}) solid $dark-yellow;
    }

    .Form-images {
      .dropzone {
        min-height: 25rem;
        margin: 0 4rem !important;

        p.fw-bold {
          font: normal normal normal 30px/35px Chivo;
        }

        p {
          font: normal normal normal 20px/20px Chivo;
        }

        button {
          font: normal normal bold 20px/25px Helvetica Neue;
          padding: 0 5rem;
        }

        img {
          width: 90px;
        }
      }

      .container-image {
        img {
          height: 10rem !important;
          width: 12rem !important;
        }

        .overlay {
          height: 10rem;
          width: 12rem;
          margin: 0 auto;
        }
      }

      .conseils {
        margin: 0 auto !important;
        width: 60%;

        .conseil-title {
          font: normal normal bold 25px/22px Chivo;
        }

        .carousel-item {
          .text-white {
            margin: auto !important;
            font: normal normal normal 20px/21px Chivo;
          }
        }
      }
    }

    .Form-input {
      p.label-required {
        font: normal normal normal 13px/13px Chivo;
      }

      label.form-check-label {
        font: normal normal normal 15px/17px Chivo;
      }

      label.form-label {
        font: normal normal normal 20px/25px Chivo;
      }

      .disclaimer {
        font: italic normal normal 13px/15px Chivo;
      }

      button.btn-submit {
        font: normal normal 17px/21px 'Helvetica Neue';
      }
    }
  }
}
